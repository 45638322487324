<template>
  <div class="qrcode">
    <div class="qr-container">
      <div class="qrbg">
        <van-image class="qrcode" :src="codeImg" />
      </div>
      <p class="desc">请扫一扫资产二维码</p>
    </div>
  </div>
</template>

<script>
import {  Button, Image } from "vant";
export default {
  name: "qrcode",
  components: {
    [Button.name]: Button,
    [Image.name]: Image,
  },
  computed:{
    codeImg(){
      return this.$route.query.codeImg
    }
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.qrcode {
  overflow: hidden;
  .qr-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0;
    width: 343px;
    height: 588px;
    margin: 12px auto;
    background: url("~images/qrbg.png") top center no-repeat;
    background-size: 343px 588px;
    border: 1px dashed #cccccc;
    .qrbg {
      width: 211px;
      height: 211px;
      background: url("~images/codebg.png") left center no-repeat;
      background-size: 211px 211px;
      display: flex;
      align-items: center;
      justify-content: center;
      .qrcode {
        width: 177px;
        height: 177px;
      }
    }
    .sao-btn {
      width: 136px;
      height: 44px;
      font-size: 16px;
      color: #377DED;
      border: 1px solid #377DED;
      margin-top: 50px;
    }
    .desc {
      font-size: 14px;
      color: #0C0F18;
      margin-top: 30px;
    }
  }
}
</style>